import * as React from "react"

import { CheckIcon } from "@heroicons/react/outline"

interface Props {
  title: string
  body: string
  items: string[]
}

const Highlights = ({ items, title, body }: Props) => (
  <>
    <div className="max-w-3xl mx-auto text-center">
      <h1 className="text-3xl font-extrabold text-gray-900">{title}</h1>
      <p className="mt-4 text-lg text-gray-500">{body}</p>
    </div>
    <div className="mt-12 justify-center space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
      {items.map(item => (
        <div key={item} className="relative flex justify-center">
          <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
          <p className="ml-3 text-lg leading-6 font-medium text-gray-900">
            {item}
          </p>
        </div>
      ))}
    </div>
  </>
)

export default Highlights
