import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Quote from "../components/quote"
import ProjectCta from "../components/projectCta"
import Highlights from "../components/highlights"
import ImageModal from "../components/imageModal"

const ProjectsPage = ({
  data: {
    contentfulProject: {
      title,
      introduction,
      jobDate,
      galleryBefore,
      customerName,
      address,
      testimonial,
      gallery,
      highlights,
    },
  },
}) => {
  const [currentImage, setCurrentImage] = useState("")

  return (
    <>
      <Layout>
        <Seo title="Projects" />

        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-16 lg:px-8">
            <Highlights
              title={`${title} - ${address}`}
              body={introduction}
              items={highlights}
            />
          </div>
        </div>

        {galleryBefore && galleryBefore.length > 0 && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <div className="pb-5 border-b border-gray-200 mb-10">
              <h2 className="text-3xl leading-6 font-extrabold text-gray-900">
                Before
              </h2>
            </div>

            <ul
              role="list"
              className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
            >
              {galleryBefore.map(
                ({ id, title, description, file: { url } }) => (
                  <li key={id} className="relative">
                    <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-teal-500 overflow-hidden">
                      <img
                        src={url}
                        alt={description}
                        className="object-cover pointer-events-none group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute inset-0 focus:outline-none"
                        onClick={() => setCurrentImage(url)}
                      >
                        <span className="sr-only">
                          View details for {title}
                        </span>
                      </button>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        )}

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="pb-5 border-b border-gray-200 mb-10">
            <h2 className="text-3xl leading-6 font-extrabold text-gray-900">
              After
            </h2>
          </div>

          <ul
            role="list"
            className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
          >
            {gallery.map(({ id, title, description, file: { url } }) => (
              <li key={id} className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-teal-500 overflow-hidden">
                  <img
                    src={url}
                    alt={description}
                    className="object-cover pointer-events-none group-hover:opacity-75"
                  />
                  <button
                    type="button"
                    className="absolute inset-0 focus:outline-none"
                    onClick={() => setCurrentImage(url)}
                  >
                    <span className="sr-only">View details for {title}</span>
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {testimonial && (
          <Quote
            withImage={false}
            isClipped={false}
            testimonial={{
              text: testimonial.testimonial,
              address,
              customerName,
            }}
          />
        )}

        <ProjectCta text="Have a project in mind?" />
      </Layout>

      <ImageModal image={currentImage} onClose={() => setCurrentImage("")} />
    </>
  )
}

export const query = graphql`
  query projectQuery($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      introduction
      jobDate
      customerName
      address
      highlights

      testimonial {
        testimonial
      }

      galleryBefore {
        id
        file {
          url
        }
        title
        description
      }

      gallery {
        id
        file {
          url
        }
        title
        description
      }
    }
  }
`

export default ProjectsPage
