import { Link } from "gatsby"
import * as React from "react"

interface Props {
  withImage?: boolean
  isClipped?: boolean
  projectLink?: string
  testimonial: {
    text: string
    address: string
    image?: string
    customerName: string
    imageAlt?: string
  }
}

const Quote = ({
  withImage = true,
  testimonial,
  isClipped = true,
  projectLink = "",
}: Props) => {
  const BodyElement = (
    <div className="relative max-w-7xl mx-auto pb-10 pt-20 px-10 sm:px-6 lg:px-8 lg:py-10">
      <div className="relative lg:flex lg:items-center">
        {withImage && testimonial.image && (
          <div className="hidden lg:block lg:flex-shrink-0 lg:mr-10">
            <div className="w-64 xl:w-80 overflow-hidden rounded-lg">
              <img
                className="w-full"
                src={testimonial.image}
                alt={testimonial.imageAlt}
              />
            </div>
          </div>
        )}

        <div className="relative lg:ml-12">
          <svg
            className="absolute top-0 left-0 transform -translate-x-8 -translate-y-16 h-40 w-36 text-teal-200 opacity-50"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 144 144"
            aria-hidden="true"
          >
            <path
              strokeWidth="2"
              d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
            />
          </svg>

          <blockquote className="relative">
            <div className="text-2xl leading-9 font-medium text-gray-900">
              <p>
                {isClipped ? (
                  <>
                    {testimonial.text.slice(0, 300)}
                    <span className="text-teal-600">…</span>
                  </>
                ) : (
                  testimonial.text
                )}
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex">
                {withImage && testimonial.image && (
                  <div className="flex-shrink-0 lg:hidden">
                    <img
                      className="h-12 w-12 rounded-full"
                      src={testimonial.image}
                      alt={testimonial.imageAlt}
                    />
                  </div>
                )}
                <div className="ml-4 lg:ml-0">
                  {testimonial.customerName && (
                    <div className="text-base font-medium text-gray-900">
                      {testimonial.customerName}
                    </div>
                  )}
                  {testimonial.address && (
                    <div className="text-base font-medium text-teal-600">
                      {testimonial.address}
                    </div>
                  )}
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  )

  if (projectLink) {
    return (
      <section className="overflow-hidden mt-10 sm:mt-24">
        <Link to={projectLink}>{BodyElement}</Link>
      </section>
    )
  }

  return (
    <section className="overflow-hidden mt-10 sm:mt-24">{BodyElement}</section>
  )
}

export default Quote
